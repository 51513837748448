.meRightImgContainer {
  width: 276px;
  height: 445px;
  overflow: hidden;
}
.meRightImgContainer img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
