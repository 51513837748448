.eduImgContainer {
  width: 276px;
  height: 445px;
  overflow: hidden;
}
.eduImgContainer img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
