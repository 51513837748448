.conversationContainer {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: 0 0 20px 0 green;
  display: flex;
  justify-content: center;
  align-items: center;
}
.conversationContainer img {
  width: 25px;
  height: 25px;
}
.footerMenus a {
  display: inline-block;
  margin: 0 10px;
  text-transform: uppercase;
  color: #777;
  font-size: 14px;
  font-weight: 500;
}
