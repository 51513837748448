.projectImgContainer {
  width: 500px;
  overflow: hidden;
  margin: 50px;
}
.projectImgContainer img {
  max-width: 100%;
}

.projectPortfolioContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
}
@media screen and (max-width: 768px) {
  .projectImgContainer {
    width: 100%;
    margin: 0;
    padding: 50px;
    box-sizing: border-box;
  }
  .projectPortfolioContainer {
    padding-top: 50px;
  }
}
